export const OrganizationPlan = {
  trial: 1,
  entry: 2,
  business: 3,
  professional: 4,
  enterprise: 5,
} as const;

export type OrganizationPlan = typeof OrganizationPlan[keyof typeof OrganizationPlan];

export const OrganizationPlanMap: {
  [key in OrganizationPlan]: {
    name: string;
    userLimit: number;
    projectLimit: number;
  }
} = {
  1: {
    name: "トライアル",
    userLimit: Number.POSITIVE_INFINITY,
    projectLimit: Number.POSITIVE_INFINITY,
  },
  2: {
    name: "エントリー",
    userLimit: 1,
    projectLimit: 3,
  },
  3: {
    name: "ビジネス",
    userLimit: 25,
    projectLimit: 40,
  },
  4: {
    name: "プロフェッショナル",
    userLimit: 50,
    projectLimit: 80,
  },
  5: {
    name: "エンタープライズ",
    userLimit: Number.POSITIVE_INFINITY,
    projectLimit: Number.POSITIVE_INFINITY,
  },
};

