import { register } from "src/excalidraw/actions/register";

export const actionToggleDuplicateMode = register({
  name: "duplicateMode",
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.duplicateModeEnabled,
  },
  perform(elements, appState) {
    return {
      appState: {
        ...appState,
        duplicateModeEnabled: !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.duplicateModeEnabled,
  contextItemLabel: "buttons.duplicateMode",
});

