import { mutateElement, newElementWith } from "src/excalidraw/element/mutateElement";
import { ElementsMap, ExcalidrawElement, NonDeletedExcalidrawElement } from "../element/types";
import {
  isGraphElement,
  isLinkElement,
  isNodeElement,
  isOriginTextElement,
} from "./element/typeChecks"; // from extensinos
import { ExcalidrawGraphElement, ExcalidrawNodeElement } from "./element/types"; // from extensinos
import { getContainerElement } from "../element/textElement";
import ColorsEx from "./constants/ColorsEx";
import { PRIORITY } from "../constants";
import { AppState } from "../types";

class CriticalPath {
  private graph: ExcalidrawGraphElement[];
  private nodes: ExcalidrawNodeElement[];
  private departure: Set<ExcalidrawNodeElement>;
  private firstDiscovered: Set<ExcalidrawNodeElement["id"]>;
  private discovered: Set<ExcalidrawNodeElement["id"]>;
  private hasCircle: boolean;

  constructor(graph: ExcalidrawGraphElement[]) {
    this.graph = graph;
    this.nodes = graph.filter((element) =>
      isNodeElement(element),
    ) as ExcalidrawNodeElement[];
    this.departure = new Set();
    this.firstDiscovered = new Set();
    this.discovered = new Set();
    this.hasCircle = false;
  }

  DFS = (node: ExcalidrawNodeElement) => {
    if (this.hasCircle) {
      return;
    }

    if (this.firstDiscovered.has(node.id)) {
      this.hasCircle = true;
      return;
    }

    this.firstDiscovered.add(node.id);

    // Marks this node as visited and goes on to the nodes
    // that are dependent on this node, the edge is node ----> u
    node?.nextDependencies?.forEach((e) => {
      if (!this.discovered.has(e)) {
        const index = this.nodes.findIndex((node) => node.id === e);
        if (index !== -1) {
          const u = this.nodes[index];
          this.DFS(u);
        }
      }
    });
    // All dependencies are resolved for this node, we can now add
    // This to the stack.
    this.discovered.add(node.id);
    this.departure.add(node);
  };

  findCriticalPath = (): Set<ExcalidrawGraphElement["id"]> => {
    const criticalPatIds: Set<ExcalidrawGraphElement["id"]> = new Set();

    this.departure = new Set();
    this.firstDiscovered = new Set();
    this.discovered = new Set();
    this.hasCircle = false;

    this.nodes.forEach((node) => {
      if (!this.discovered.has(node.id)) {
        this.DFS(node);
      }
    });

    if (this.hasCircle) {
      return criticalPatIds;
    }

    const costs: Map<ExcalidrawNodeElement["id"], number> = new Map<
      ExcalidrawNodeElement["id"],
      number
    >();
    const roots: Map<ExcalidrawNodeElement["id"], string> = new Map<
      ExcalidrawNodeElement["id"],
      string
    >();
    this.departure.forEach((node) => {
      const indegree = node?.prevDependencies
        ? node?.prevDependencies.length
        : 0;
      if (indegree === 0) {
        costs.set(node.id, -node.duration);
      } else {
        costs.set(node.id, Number.MAX_SAFE_INTEGER);
      }
    });

    Array.from(this.departure)
      .reverse()
      .forEach((v) => {
        v?.nextDependencies?.forEach((e) => {
          const index = this.nodes.findIndex((node) => node.id === e);

          if (index !== -1) {
            const u = this.nodes[index];
            const w = u.duration * -1;

            const vCost = Number(costs.get(v.id));
            const uCost = Number(costs.get(u.id));

            if (vCost !== Number.MAX_SAFE_INTEGER && vCost + w < uCost) {
              costs.set(u.id, vCost + w);
              roots.set(u.id, v.id);
            }
          }
        });
      });

    const queue: ExcalidrawNodeElement["id"][] = [];
    const arrival = Array.from(costs.keys()).filter(
      (key) => costs.get(key) === Math.min(...costs.values()),
    );
    arrival.forEach((arrivalId) => {
      let lastNodeId = arrivalId;
      queue.push(arrivalId);
      criticalPatIds.add(arrivalId);

      while (queue.length > 0) {
        const keyId = queue.shift() as ExcalidrawNodeElement["id"];

        const nodeId = roots.get(keyId);
        if (nodeId) {
          queue.push(nodeId);
          criticalPatIds.add(nodeId);

          const linkElement = this.graph.find(
            (element) =>
              isLinkElement(element) &&
              element.startBinding?.elementId === nodeId &&
              element.endBinding?.elementId === lastNodeId,
          );

          if (linkElement) {
            criticalPatIds.add(linkElement.id);
          }

          lastNodeId = nodeId;
        }
      }
    });

    return criticalPatIds;
  };

  static calcCriticalPath = (
    elements: readonly ExcalidrawElement[],
    elementsMap: ElementsMap,
    appState: AppState,
  ): ExcalidrawElement[] => {
    const graph = elements.filter((element) =>
      !element.isDeleted && isGraphElement(element) && (element.isCriticalPathEnabled ?? true),
    ) as ExcalidrawGraphElement[];

    const criticalPath = new CriticalPath(graph);
    const criticalPathIds = criticalPath.findCriticalPath();

    const nextElements = elements.map((element) => {
      let isCriticalPath = false;

      // CHANGED:UPDATE 2023/8/29 #961
      // if (criticalPathIds.has(element.id)) {
      //   isCriticalPath = true;
      // }

      // if (isGraphElement(element)) {
      //   if (element.isCriticalPath !== isCriticalPath) {
      //     return newElementWith(element, {
      //       isCriticalPath,
      //     });
      //   }
      // }
      if (isGraphElement(element)) {
        if (criticalPathIds.has(element.id)) {
          isCriticalPath = true;
        }

        if (element.isCriticalPath !== isCriticalPath) {
          return newElementWith(element, {
            isCriticalPath,
            priority: isCriticalPath
              ? PRIORITY[`${element.type}-cp`]
              : PRIORITY[element.type],
          });
        }
      } else if (isOriginTextElement(element)) {
        const container = getContainerElement(element, elementsMap);

        if (container && isGraphElement(container)) {
          if (criticalPathIds.has(container.id)) {
            isCriticalPath = true;
          }

          return newElementWith(element, {
            // strokeColor: isCriticalPath
            //   ? appState.criticalPathColor
            //   : container.strokeColor,
            priority: isCriticalPath
              ? PRIORITY["text-cp"]
              : PRIORITY["text"],
          });
        }
      }

      return element;
    });

    return nextElements;
  };

  static getCriticalPathIds = (
    elements: readonly NonDeletedExcalidrawElement[],
  ): Set<ExcalidrawGraphElement["id"]> => {
    const graph = elements.filter((element) =>
      isGraphElement(element) && (element.isCriticalPathEnabled ?? true),
    ) as ExcalidrawGraphElement[];

    const criticalPath = new CriticalPath(graph);

    return criticalPath.findCriticalPath();
  };
}

export default CriticalPath;
