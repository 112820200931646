import { ToolButton } from "src/excalidraw/components/ToolButton";
import { isTextElement, updateTextElement } from "src/excalidraw/element";
import { getBoundTextElement, getBoundTextElementId, getContainerElement } from "src/excalidraw/element/textElement";
import { ElementsMap, ExcalidrawElement, NonDeletedExcalidrawElement } from "src/excalidraw/element/types";
import { t } from "src/excalidraw/i18n";
import { getSelectedElements } from "src/excalidraw/scene";
import { AppState } from "src/excalidraw/types";
import { arrayToMap } from "src/excalidraw/utils";
import { register } from "src/excalidraw/actions/register";
import Calendar from "../calendar";
import EditTaskElement, { UpdateDataType } from "../components/EditTaskElement";
import EditMultipleTaskElement from "../components/EditMultipleTaskElement";
import CriticalPath from "../criticalPath";
import {
  isBindableElementEx,
  isCommentElement,
  isCommentableElement,
  isJobRelatedElement,
  isLinkElement,
  isTaskElement,
} from "../element/typeChecks";
import {
  ExcalidrawCommentElement,
  ExcalidrawCommentableElement,
  ExcalidrawNodeElement,
  ExcalidrawTaskElement,
} from "../element/types"; // UPDATED: REMOVE #1225
import { moveTaskElement } from "../moveTask";
import { newElementWith } from "src/excalidraw/element/mutateElement";
import { newCommentElement } from "../element/newElement";
import {
  COMMENT_OFFSET_X,
  COMMENT_OFFSET_Y,
  GRID_SIZE,
  PRIORITY,
} from "src/excalidraw/constants";
import { KEYS } from "../../keys";
import TaskModel from "src/conpath/models/TaskModel";
import { ShapeCache } from "../../scene/ShapeCache";

// CHANGED:ADD 2023-2-6 #541
const moveSelectedTaskElement = (
  selectedElement: ExcalidrawTaskElement,
  elementsMap: ElementsMap,
  elements: readonly ExcalidrawElement[],
  startDate: Date,
  endDate: Date,
  holidays: string[] | null,
  memo: string,
  isCriticalPathEnabled: boolean,
  appState: Readonly<AppState>,
) => {
  const calendar = new Calendar(
    appState.gridSize,
    appState.projectStartDate,
    appState.holidays,
  );

  const updatedElements = moveTaskElement(
    selectedElement,
    elementsMap,
    startDate,
    endDate,
    holidays,
    memo,
    isCriticalPathEnabled,
    appState,
    calendar,
  );

  if (updatedElements.length === 0) {
    return null;
  }

  const updatedElementsMap = arrayToMap(updatedElements);

  return elements.map(
    (element) => updatedElementsMap.get(element.id) || element,
  );
};

// CHANGED:ADD 2023-1-26 #517
export const actionToggleEditTask = register({
  name: "toggleEditTask",
  contextItemLabel: "labels.edit",
  trackEvent: { category: "menu" },
  predicate: (elements, appState, _, app) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.some((el) => isTaskElement(el));
  },
  perform: (_elements, appState, _, { focusContainer }) => {
    if (appState.openDialog === "editTask" || appState.openDialog === "editMultipleTask") {
      focusContainer();
    }

    const selectedElements = getSelectedElements(_elements, appState);
    const tasks = selectedElements.filter((el) => isTaskElement(el));
    if (tasks.length === 0) {
      return {
        commitToHistory: false,
      };
    }

    const isSingleTask = tasks.length === 1;

    return {
      appState: {
        ...appState,
        openDialog: isSingleTask
          ? appState.openDialog === "editTask" ? null : "editTask"
          : appState.openDialog === "editMultipleTask" ? null : "editMultipleTask",
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      title={`${t("buttons.edit")} — ${KEYS.E.toUpperCase()}`}
      aria-label={t("buttons.edit")}
      onClick={() => updateData(null)}
      className="ToolIcon_text_button"
    >
      {t("buttons.edit")}
    </ToolButton>
  ),
  keyTest: (event) => event.key === KEYS.E, // CHANGED:ADD 2024-04-04 #1897
});

export const actionEditTaskElement = register({
  name: "editTaskElement",
  trackEvent: { category: "element" },
  perform: (elements, appState, value, app) => {
    if (value.type === UpdateDataType.UPDATE) {
      const selectedElement = app.scene.getSelectedElements({
        selectedElementIds: appState.selectedElementIds,
        includeBoundTextElement: false, //CHANGED:UPDATE 2023-02-15 #714
      })[0] as ExcalidrawTaskElement;
      const elementsMap = app.scene.getNonDeletedElementsMap();
      const updatedElements = moveSelectedTaskElement(
        selectedElement,
        app.scene.getElementsMapIncludingDeleted(),
        elements,
        value.startDate,
        value.endDate,
        value.holidays,
        value.memo,
        value.isCriticalPathEnabled,
        appState,
      );

      if (!updatedElements) {
        return {
          appState: {
            ...appState,
            errorMessage: t("errors.invalidEditTask"),
          },
          commitToHistory: false,
        };
      }

      const boundText = getBoundTextElement(selectedElement, elementsMap);
      const nextElements = updatedElements.map((el) => {
        if (el.id === boundText?.id) {
          return updateTextElement(
            boundText!,
            getContainerElement(boundText, elementsMap),
            elementsMap,
            {
              text: value.title,
              originalText: value.title,
            });
        }

        return el;
      });

      return {
        elements: CriticalPath.calcCriticalPath(nextElements, elementsMap, appState),
        commitToHistory: true,
      };
    } else if (value.type === UpdateDataType.CLOSE_STATUS) {
      const selectedElements = app.scene.getSelectedElements({
        selectedElementIds: appState.selectedElementIds,
        includeBoundTextElement: true,
      }).filter((element) => !isJobRelatedElement(element));
      if (!selectedElements.length) {
        return false;
      }

      const selectedElementsMap = arrayToMap(selectedElements);
      const isClosed = value.isClosed;

      elements.forEach((element) => {
        if (
          !selectedElementsMap.has(element.id) &&
          isLinkElement(element) &&
          element.startBinding &&
          selectedElementsMap.has(element.startBinding.elementId)
        ) {
          selectedElementsMap.set(element.id, element);
        }
      });

      selectedElementsMap.forEach((element) => {
        if (isCommentableElement(element)) {
          const thread = elements.find((el) => isCommentElement(el) && el.commentElementId === element.id);
          if (thread) selectedElementsMap.set(thread.id, thread);
        }
      });

      return {
        elements: elements.map((element) => {
          if (
            !selectedElementsMap.has(element.id) &&
            !(
              isTextElement(element) &&
              element.containerId &&
              selectedElementsMap.has(element.containerId)
            )
          ) {
            return element;
          }

          return newElementWith(element, { isClosed });
        }),
        appState: {
          ...appState,
          selectedLinearElement: isClosed ? null : appState.selectedLinearElement,
          selectedTaskElement: isClosed ? null : appState.selectedTaskElement,
          selectedLinkElement: isClosed ? null : appState.selectedLinkElement,
        },
        commitToHistory: true,
      };
    } else if (value.type === UpdateDataType.CHANGE_LAYER) {
      const selectedElement = app.scene.getSelectedElements({
        selectedElementIds: appState.selectedElementIds,
        includeBoundTextElement: false, //CHANGED:UPDATE 2023-02-15 #714
      })[0];
      const newLayer: number = value.layer || 0;
      const elementsMap = app.scene.getNonDeletedElementsMap();
      const updatedElementIds: Set<ExcalidrawElement["id"]> = new Set();
      const updatedElements: NonDeletedExcalidrawElement[] = [];
      const queue: ExcalidrawNodeElement[] = [];
      const discovered: Set<ExcalidrawElement["id"]> = new Set();

      if (isTaskElement(selectedElement)) {
        discovered.add(selectedElement.id);
        queue.push(selectedElement);

        updatedElementIds.add(selectedElement.id);

        const textElementId = getBoundTextElementId(selectedElement);
        if (textElementId) {
          updatedElementIds.add(textElementId);
        }

        while (queue.length > 0) {
          const v = queue.shift() as ExcalidrawNodeElement;

          const dependencies = [
            ...v.nextDependencies || [],
            ...v.prevDependencies || [],
          ];

          dependencies?.forEach((e) => {
            if (!discovered.has(e)) {
              discovered.add(e);

              const u = elementsMap.get(e);
              if (u && isBindableElementEx(u, true)) {
                updatedElementIds.add(u.id);

                const textElementId = getBoundTextElementId(u);
                if (textElementId) {
                  updatedElementIds.add(textElementId);
                }

                const linkElement = Array.from(elementsMap.values()).find(
                  (element) =>
                    isLinkElement(element) &&
                    (
                      (element.startBinding?.elementId === v.id && element.endBinding?.elementId === u.id) ||
                      (element.startBinding?.elementId === u.id && element.endBinding?.elementId === v.id)
                    ),
                );

                if (linkElement) {
                  updatedElementIds.add(linkElement.id);

                  const textElementId = getBoundTextElementId(linkElement);
                  if (textElementId) {
                    updatedElementIds.add(textElementId);
                  }
                }
                queue.push(u);
              }
            }
          });
        }
      }

      updatedElementIds.forEach((updatedElementId) => {
        const element = elementsMap.get(updatedElementId);
        if (element) {
          ShapeCache.delete(element);
          updatedElements.push(
            newElementWith(element, { layer: newLayer })
          );
        }
      });

      const updatedElementsMap = arrayToMap(updatedElements);
      const nextElements = elements.map((element) =>
        updatedElementsMap.get(element.id) || element
      );

      return {
        elements: nextElements,
        commitToHistory: true,
      };
    } else if (value.type === UpdateDataType.CREATE_COMMENT_ELEMENT) {
      if (!value.commentableElement || !value.commentId) return false;
      const commentableElement = value.commentableElement as ExcalidrawCommentableElement;

      const existingCommentOnElement = elements.find((el) =>
        isCommentElement(el) &&
        el.commentElementId === value.commentableElement.id &&
        !el.isDeleted
      );
      // コメントエレメントが存在しない場合のみコメントエレメントを作成し、canvasに表示
      if (!existingCommentOnElement) {
        const commentElement = newCommentElement({
          id: value.commentId,
          x: commentableElement.x + commentableElement.width + COMMENT_OFFSET_X,
          y: commentableElement.y + COMMENT_OFFSET_Y,
          strokeColor: appState.currentItemStrokeColor,
          strokeWidth: appState.currentItemStrokeWidth,
          width: GRID_SIZE,
          height: GRID_SIZE,
          priority: PRIORITY.comment,
          commentElementId: commentableElement.id,
          isVisible: true,
          layer: commentableElement.layer, // CHANGED:ADD 2024-10-7 #2114
        });
        elements = elements.concat(commentElement);
      }

      return {
        elements: elements,
        commitToHistory: false,
      };
    } else if (value.type === UpdateDataType.DELETE_COMMENT_ELEMENT) {
      if (!value.commentableElement) return false;
      const commentableElement = value.commentableElement as ExcalidrawCommentableElement;

      elements = elements.map((el) => {
        if (isCommentElement(el) && el.commentElementId === commentableElement.id) {
          return newElementWith(el, { isDeleted: true });
        }
        return el;
      });

      return {
        elements: elements,
        commitToHistory: false,
      };
    }

    return false;
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });

    let element: ExcalidrawTaskElement | null = null;

    if (selectedElements.filter((el) => isTaskElement(el)).length === 1) {
      element = selectedElements.find((el) =>
        isTaskElement(el),
      ) as ExcalidrawTaskElement | null;
    }

    if (!element) {
      return null;
    }

    const commentElement = elements.find((el) =>
      isCommentElement(el) &&
      el.commentElementId === element!.id &&
      !el.isDeleted) as ExcalidrawCommentElement | undefined;

    const title = getBoundTextElement(
      element,
      app.scene.getNonDeletedElementsMap(),
    )?.originalText;

    return (
      <EditTaskElement
        element={element}
        commentElement={commentElement}
        title={title}
        appState={appState}
        updateData={updateData}
      />
    );
  },
});

// CHANGED:ADD 2024-04-04 #1847
export const actionEditMultipleTask = register({
  name: "editMultipleTaskElement",
  viewMode: true,
  contextItemLabel: "labels.editMultipleTask",
  trackEvent: { category: "menu" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.filter((el) => isTaskElement(el)).length > 1;
  },
  perform: (elements, appState, value, app) => {
    if (appState.openDialog === "editMultipleTask") {
      app.focusContainer();
    }

    const updateTasks: TaskModel[] = value.updateTasks || [];
    const elementsMap = app.scene.getNonDeletedElementsMap();
    const updatedElementIds: Set<ExcalidrawElement["id"]> = new Set();
    const updatedElements: NonDeletedExcalidrawElement[] = [];
    const queue: ExcalidrawNodeElement[] = [];
    const discovered: Set<ExcalidrawElement["id"]> = new Set();
    updateTasks.forEach((taskModel) => {
      const taskElement = elementsMap.get(taskModel.id);
      if (isTaskElement(taskElement)) {
        discovered.add(taskElement.id);
        queue.push(taskElement);

        updatedElementIds.add(taskElement.id);

        const textElementId = getBoundTextElementId(taskElement);
        if (textElementId) {
          updatedElementIds.add(textElementId);
        }

        while (queue.length > 0) {
          const v = queue.shift() as ExcalidrawNodeElement;

          const dependencies = [
            ...v.nextDependencies || [],
            ...v.prevDependencies || [],
          ];

          dependencies?.forEach((e) => {
            if (!discovered.has(e)) {
              discovered.add(e);

              const u = elementsMap.get(e);
              if (u && isBindableElementEx(u, true)) {
                updatedElementIds.add(u.id);

                const textElementId = getBoundTextElementId(u);
                if (textElementId) {
                  updatedElementIds.add(textElementId);
                }

                const linkElement = Array.from(elementsMap.values()).find(
                  (element) =>
                    isLinkElement(element) &&
                    (
                      (element.startBinding?.elementId === v.id && element.endBinding?.elementId === u.id) ||
                      (element.startBinding?.elementId === u.id && element.endBinding?.elementId === v.id)
                    ),
                );

                if (linkElement) {
                  updatedElementIds.add(linkElement.id);

                  const textElementId = getBoundTextElementId(linkElement);
                  if (textElementId) {
                    updatedElementIds.add(textElementId);
                  }
                }
                queue.push(u);
              }
            }
          });
        }
      }

      updatedElementIds.forEach((updatedElementId) => {
        const element = elementsMap.get(updatedElementId);
        if (element) {
          ShapeCache.delete(element);
          updatedElements.push(
            newElementWith(element, { layer: taskModel.layer })
          );
        }
      });
    });

    const updatedElementsMap = arrayToMap(updatedElements);
    const nextElements = elements.map((element) =>
      updatedElementsMap.get(element.id) || element
    );

    return {
      elements: nextElements,
      appState: {
        ...appState,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });

    const taskElements = selectedElements.filter(
      (el) => isTaskElement(el)
    ) as ExcalidrawTaskElement[];

    if (taskElements.length === 0) {
      return null;
    }

    return (
      <EditMultipleTaskElement
        elements={taskElements}
        elementsMap={app.scene.getNonDeletedElementsMap()}
        appState={appState}
        updateData={updateData}
      />
    );
  },
});