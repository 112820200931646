import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

//mobx
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

//styles
import "./ResetPassword.scss";
import Colors from "src/conpath/constants/Colors";

//constants
import { Paths } from "src/conpath/constants/Routes";


const ResetPassword = observer(() => {

  const { userStore } = useStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasEmailSent, setHasEmailSent] = useState<boolean>(false);


  useEffect(() => {

    return () => {
      setHasEmailSent(false);
    }
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setEmail(value);
    }
    setErrorText("");
  };

  const onSubmit = async () => {
    if (!userStore) return;


    setIsLoading(true);
    const result = await userStore.sendPasswordResetEmail(email);

    setIsLoading(false);
    if (result.error) {
      setErrorText(result.error);
      return;
    }
    setHasEmailSent(true);
  }

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    // Check for the `Enter` key
    if (e.key === "Enter") {
      onSubmit(); // Call your onSubmit function
    }
  };

  const navigateToLoginScreen = () => {
    navigate(Paths.login);
  }

  const onResendEmailTextPressed = () => {
    setHasEmailSent(false);
  }

  return (
    <div className="input__body">
      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
        {
          hasEmailSent ?
          (
            <div className="input__card topSpacer">
              <h3 className="extended-title">パスワード再設定のメールを送信しました。</h3>
              <div className="input__wrapper minSpacer">
                <p className="extended-label">
                  メールに添付されているURLからパスワードの再設定を行なってください。
                </p>
                <p className="extended-label">
                  メールが届いていませんか？
                  <a onClick={onResendEmailTextPressed}>
                    メールの再送信はこちら。
                  </a>
                </p>
                <div className="input__buttonWrapper">
                  <button onClick={navigateToLoginScreen} className="button main">
                    ログイン画面へ戻る
                  </button>
                </div>
              </div>
            </div>
          )
          :
          (     
            <div className="input__card topSpacer">
              <h2 className="input__title">パスワード再設定のメールを送信する</h2>
              <div className="error-text-wrapper">
                <p>{errorText}</p>
              </div>
              <p className="input__label">ご登録されているメールアドレスを入力してください。</p>
              <div className="input__wrapper minSpacer">
                <p className="input__label">メールアドレス</p>
                <input
                  value={email}
                  onChange={onChange}
                  onKeyDown={onKeyPress}
                  type="email"
                  name="email"
                  placeholder="メールアドレス"
                />
              </div>
              {isLoading ? (
                <div className="spinner-wrapper">
                  <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="32"
                    visible={true}
                  />
                </div>
              ) : (
                <div className="input__buttonWrapper">
                  <button onClick={onSubmit} className="button main">
                    送信
                  </button>
                </div>
              )}
            </div>
          )
        }
    </div>
  )
});

export default ResetPassword;