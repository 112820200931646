

export interface ResourcesFilter {
  searchText: string | null;
  searchAssignUser: string | null;
  searchAssignResource: string | null;
  searchProject: string | null;
  searchMilestone: string | null;
  searchTag: string | null;
  showOnlyTaskExists: boolean;
  showOnlyOverlapTasks: boolean;
  showOnlyBlankTasks: boolean;
};

export const ResourcesFilterTags = {
  searchText: "テキスト",
  searchAssignUser: "担当者",
  searchAssignResource: "リソース",
  searchProject: "プロジェクト",
  searchMilestone: "マイルストーン",
  searchTag: "タグ",
};