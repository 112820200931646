

export interface RoadmapFilter {
  searchText: string | null;
  searchStatus: string | null;
  searchAssignUser: string | null;
  searchAssignResource: string | null;
  searchProject: string | null;
  searchMilestone: string | null;
  searchTag: string | null;
  showOnlyDelayedTasks: boolean;
};

export const RoadmapFilterTags = {
  searchText: "テキスト",
  searchStatus: "ステータス",
  searchAssignUser: "担当者",
  searchAssignResource: "リソース",
  searchProject: "プロジェクト",
  searchMilestone: "マイルストーン",
  searchTag: "タグ",
};